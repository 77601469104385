import React from 'react';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';

function NavBase(props) {
  const logged_out_nav = (
      <LoginForm handle_login={props.handle_login}/>
  );
  const logged_in_nav = (
      <label className="card-title" onClick={props.handle_logout}>logout</label>
  );
  return <div> {props.logged_in ? logged_in_nav : logged_out_nav}</div>
}

export default NavBase;

NavBase.propTypes = {
  logged_in: PropTypes.bool.isRequired,
  display_form: PropTypes.func.isRequired,
  handle_logout: PropTypes.func.isRequired
};