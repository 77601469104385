import React, { Component } from 'react';

import NavBase from './components/NavBase';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './ES_pri_rgb_rev_dark.png';
import {Navbar, Nav, NavDropdown, Tabs, Tab} from 'react-bootstrap';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_url: process.env.NODE_ENV == 'production' ? 'http://localhost:8000/' : 'http://127.0.0.1:8000/',
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      username: ''
    };
    localStorage.removeItem('token');
  }
  componentDidMount() {
    if (this.state.logged_in) {
      fetch(this.state.base_url + 'core/current_user/', {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(json => {
          this.setState({ username: json.username });
        });
    }
  }
  handle_login = (e, data) => {
    console.log(process.env.NODE_ENV);
    console.log(data);
    e.preventDefault();
    fetch(this.state.base_url + 'api-token-auth/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        if ('token' in json) {
          localStorage.setItem('token', json.token);
          this.setState({
            logged_in: true,
            displayed_form: '',
            username: data.username
          });
        }

      });
  };

  handle_signup = (e, data) => {
    e.preventDefault();
    fetch(this.state.base_url + 'core/users/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(json => {
        localStorage.setItem('token', json.token);
        this.setState({
          logged_in: true,
          displayed_form: '',
          username: json.username
        });
      });
  };

  handle_logout = () => {
    localStorage.removeItem('token');
    this.setState({ logged_in: false, username: '' });
  };

  display_form = form => {
    this.setState({
      displayed_form: form
    });
  };
  render() {
    let form;
    switch (this.state.displayed_form) {
      case 'login':
        form = <LoginForm handle_login={this.handle_login}/>;
        break;
      case 'signup':
        form = <SignupForm handle_signup={this.handle_signup}/>;
        break;
      default:
        form = null;
    }
    var links = (<Nav className="mr-auto">
      <Nav.Link href="#home">Home</Nav.Link>
    </Nav>);
    if (this.state.logged_in) {

      links = (
        <Nav className="mr-auto" >
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#link">Analysis</Nav.Link>
          <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      );
    }
  return (
      <div className="App">

        {form}
        <Navbar class="border-light" sticky="top" bg="dark" variant="dark" expand="lg">
        <Navbar.Brand href="#home">
          <img
            alt=""
            src={logo}
            width="100"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          ES Toolbox
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {links}
          <NavBase
            logged_in={this.state.logged_in}
            display_form={this.display_form}
            handle_logout={this.handle_logout}
            handle_login={this.handle_login}
          />
        </Navbar.Collapse>
      </Navbar>
      <header className="App-header">
          <div className="card text-white bg-dark border-light" style={{marginLeft: '3%', width: '94%', height: 600}}>
            <div className="card-body">
              <h4 className="card-title">Card title</h4>
              <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the
                card's content.</p>
              <a href="#" className="card-link">Card link</a>
              <a href="#" className="card-link">Another link</a>
                          <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
  <Tab eventKey="home" title="Home">
    Text
  </Tab>
  <Tab eventKey="profile" title="Profile">
    Text
  </Tab>
  <Tab eventKey="contact" title="Contact" disabled>
   Text
  </Tab>
</Tabs>
            </div>

          </div>

      </header>
      </div>
    );
  };
}

export default App;
